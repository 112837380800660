<template>
    <div :class="{dark: !loaded && cubes, darker:!loaded && !cubes}" class="app">
        <AppHeader fixed>
            <div class="navbar-brand">
                <img class="navbar-brand-full" :src="logo" width="100%" style="margin-left: 10%; padding-right: 5%">
                <img class="navbar-brand-minimized" :src="logoSm" width="100%" style="padding: 8px">
            </div>
        </AppHeader>
        <div class="app-body">
        </div>
        <TheFooter v-if="isMobile()" style="display: block">
            <p style="text-align: center">
                <a href="#">MTOP Satellite</a>
                &copy; 2021 MTOP
                <br>
                Powered by
                <a href="http://monteltech.com">MTOP Enterprise</a></p>
        </TheFooter>
        <TheFooter v-else>
            <!--footer-->
            <div>
                <a href="#">MTOP Satellite</a>
                <span class="ml-1">&copy; 2021 MTOP</span>
            </div>
            <div class="ml-auto">
                <span class="mr-1">Powered by</span>
                <a href="http://monteltech.com">MTOP Enterprise</a>
            </div>
        </TheFooter>
    </div>
</template>

<script>
import {
    Footer as TheFooter,
    Header as AppHeader
} from '@/views/template_files/navbarJazz';

export default {
    name: 'SharedStream',
    components: {
        AppHeader,
        TheFooter
    },
    data() {
        return {
            cubes: true,
            loaded: true,
            logo: '/img/mtoplogo.png',
            logoSm: '/img/mtoplogosmall.png'
        }
    },
    created() {},
    methods: {
        isMobile() {
            return (screen.width < 992) && !JSON.stringify(window.location.href).includes("video-wall");
        }
    }
}
</script>

<style src="spinkit/spinkit.css" lang="css"/>

<style scoped>
.sk-cube {
    background-color: #f3f4f5;
}

.dark {
    opacity: .5;
}

.darker {
    opacity: .1;
}
</style>

